import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";
import { CenterDetail, CenterStaffList, centerStaffQueryKey } from "./use-center";
import invariant from "tiny-invariant";

interface StaffMemberShared {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  businessEmail: string;
  mobilePhone: string;
  homePhone: string;
  otherPhone: string;
  isActive: boolean;
}
export interface StaffMember extends StaffMemberShared {
  centerId?: string;
  staffId?: string;
  role?: string;
  position?: string;
  isRehireable?: boolean;
}

export interface StaffMemberContactDetails extends StaffMemberShared {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipcode: string;
  personalEmail: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  hasMailbox: boolean;
  createMailbox: boolean;
  employeeNumber: string | number | null;
}

export const DefaultEditableStaffMemberContactDetail: StaffMemberContactDetails = {
  id: "",
  firstName: "",
  lastName: "",
  displayName: "",
  businessEmail: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  zipcode: "",
  mobilePhone: "",
  homePhone: "",
  otherPhone: "",
  personalEmail: "",
  emergencyContactName: "",
  emergencyContactPhone: "",
  isActive: false,
  hasMailbox: false,
  createMailbox: false,
  employeeNumber: ""
} as const;

export interface CreateNewUserPayload
  extends Omit<
    StaffMemberContactDetails & StaffMember,
    "businessEmail" | "centerId" | "jobTitle" | "isActive" | "isRehireable" | "hasMailbox"
  > {
  startDate: string; // YYYY-MM-DD
  createMailbox: boolean;
  centers: string[];
  jobType: string;
}

export interface AccountInformation {
  createMailbox: boolean;
  role: string;
  position: string;
  startDate: string;
  jobType: string;
  centers: string[];
}

const staffMemberQueryKey = (options: { centerId?: string; staffMemberId?: string }) =>
  ["staffMember", options] as const;
export const useStaffMember = ({ centerId, staffMemberId }: { centerId?: string; staffMemberId?: string }) => {
  const { getToken } = useAccessToken();

  return useQuery({
    queryKey: staffMemberQueryKey({ centerId, staffMemberId }),
    queryFn: async () => {
      invariant(centerId && staffMemberId, "centerId and staffMemberId must be defined");
      return getCenterStaffMember(await getToken(), { centerId, staffMemberId });
    },
    enabled: Boolean(centerId && staffMemberId)
  });
};

const availableCentersQueryKey = (staffMemberId?: string) => ["availableCenters", staffMemberId] as const;
export const useAvailableCenters = (staffMemberId?: string) => {
  const { getToken } = useAccessToken();

  return useQuery({
    queryKey: availableCentersQueryKey(staffMemberId),
    queryFn: async () => getAvailableCentersList(await getToken(), staffMemberId)
  });
};

export const useUpdateStaffMember = (centerId: string | undefined, staffMemberId: string | undefined) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  return useMutation({
    mutationFn: async (updatedData: StaffMemberContactDetails) => {
      invariant(centerId, "centerId must be defined");
      invariant(staffMemberId, "staffMemberId must be defined");
      return updateStaffMember(await getToken(), centerId, staffMemberId, {
        ...updatedData,
        firstName: updatedData.firstName.trim(),
        lastName: updatedData.lastName.trim()
      });
    },

    onSuccess: updated => {
      queryClient.setQueryData<StaffMemberContactDetails>(staffMemberQueryKey({ centerId, staffMemberId }), updated);
      queryClient.setQueryData<CenterStaffList>(centerStaffQueryKey(centerId), previous => {
        // one of those probably impossible in practice but need to account for since react-query
        // theoretically allows it
        // istanbul ignore if
        if (!previous || !previous.staff) {
          return { staff: [updated] };
        } else {
          const foundIndex = previous.staff.findIndex(sm => sm.id === updated.id);
          const staffListCopy = [...previous.staff];
          const [beforeUpdate = {}] = staffListCopy.splice(foundIndex, foundIndex >= 0 ? 1 : 0);

          return {
            staff: [...staffListCopy, { ...beforeUpdate, ...updated }]
          };
        }
      });
    }
  });
};

export const useUser = (centerId: string | undefined) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const newUserMutator = useMutation({
    mutationFn: async (newUser: CreateNewUserPayload) =>
      createNewUser(await getToken(), {
        ...newUser,
        firstName: newUser.firstName.trim(),
        lastName: newUser.lastName.trim()
      }),

    onSuccess: updated => {
      queryClient.invalidateQueries(["staffMemberCentersData", centerId, updated.id]);
      queryClient.invalidateQueries(["centerStaffData", centerId]);
    }
  });

  return { newUserMutator };
};

const getCenterStaffMember = (
  accessToken: string,
  { centerId, staffMemberId }: { centerId: string; staffMemberId: string }
) => callLcosService<StaffMemberContactDetails>(accessToken, `/api/users/${centerId}/${staffMemberId}`);

const updateStaffMember = (
  accessToken: string,
  centerId: string,
  staffMemberId: string,
  payload: StaffMemberContactDetails
) => callLcosService<StaffMemberContactDetails>(accessToken, `/api/users/${centerId}/${staffMemberId}`, "PUT", payload);

const getAvailableCentersList = async (accessToken: string, userId: string | undefined) => {
  const queryParams = userId ? `?user_id=${userId}` : "";
  const { centers } = await callLcosService<{ centers: CenterDetail[] }>(
    accessToken,
    `/api/users/new/available-centers${queryParams}`
  );
  return centers;
};

const createNewUser = (accessToken: string, payload: CreateNewUserPayload) => {
  return callLcosService<StaffMember>(accessToken, `/api/users/new`, "POST", payload);
};
