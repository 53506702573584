import type { OptOutOption } from "../../components/inquiry-form/opt-out-multiselect";
import type { QuickCreateInquirerFormValues } from "../../components/inquiry-form/quick-create-inquirer";
import type { QuickCreateStudentFormValuesInput } from "../../components/inquiry-form/quick-create-student";
import type { FamilyContactDetail } from "./types";

export const defaultFamilyContactDetail = {
  id: undefined,
  title: "",
  firstName: "",
  lastName: "",
  suffix: "",
  // contactType: "",
  gender: "",
  dob: "",
  age: "",
  grade: {
    value: "",
    name: ""
  },
  gradeId: "",
  school: "",
  email: "",
  homePhone: "",
  businessPhone: "",
  personalPhone: "",
  preferredContactMethod: "",
  optOut: [] as OptOutOption[],
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipcode: "",
  relationshipId: undefined
} as const satisfies FamilyContactDetail &
  QuickCreateStudentFormValuesInput &
  QuickCreateInquirerFormValues & { id?: number };
