import React from "react";

import { useAccessControl } from "../../../app/access-control-context";
import { useStaffMember } from "../../../hooks/use-user";
import { SimpleTable } from "../../table";
import { FormHelpers } from "../../forms";

export interface ContactProps {
  staffMemberId: string;
  onEdit?: () => void;
}

export const Contact: React.FC<ContactProps> = ({ staffMemberId, onEdit }) => {
  const { activeCenterId, hasFeatureAccess } = useAccessControl();
  const staffMemberQuery = useStaffMember({ centerId: activeCenterId, staffMemberId });

  return (
    <>
      <SimpleTable
        isLoading={staffMemberQuery.isLoading}
        tableCollapseScreenSize="lg"
        uniqueIdKey="id"
        headers={[
          {
            name: "Staff Name",
            renderKey: "displayName",
            render: ({ displayName }) => <a href="#">{displayName}</a>,
            tableDataCellProps: { className: "td-primary" }
          },
          {
            name: "HLC Account",
            renderKey: "businessEmail",
            render: ({ businessEmail }) => <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
          },
          {
            name: "Grant Email Access",
            renderKey: "hasMailbox",
            render: ({ hasMailbox }) =>
              hasMailbox ? (
                <span className="fas fa-check-circle active" aria-hidden="true" />
              ) : (
                <span className="fas fa-times-circle" aria-hidden="true" />
              ),
            tableDataCellProps: { className: "td-icon" },
            tableHeaderCellProps: { className: "text-center" }
          },
          {
            name: "Publish Staff Info",
            renderKey: "publishStaffInfo",
            render: ({ hasMailbox }) =>
              hasMailbox ? (
                <span className="fas fa-check-circle active" aria-hidden="true" />
              ) : (
                <span className="fas fa-times-circle" aria-hidden="true" />
              ),
            tableDataCellProps: { className: "td-icon" },
            tableHeaderCellProps: { className: "text-center" }
          },
          ...(hasFeatureAccess?.("employee_id")
            ? [
                {
                  name: "HR ID",
                  renderKey: "employeeNumber",
                  render: ({ employeeNumber }: { employeeNumber: string | number | null }) => employeeNumber,
                  tableDataCellProps: { className: "text-right" },
                  tableHeaderCellProps: { className: "text-right" }
                }
              ]
            : [])
        ]}
        data={staffMemberQuery.data ? [staffMemberQuery.data] : []}
        onRowClick={onEdit}
      />

      <h3 className="card-subheader">Contact</h3>
      <SimpleTable
        className="vertical-top"
        isLoading={staffMemberQuery.isLoading}
        tableCollapseScreenSize="lg"
        uniqueIdKey="id"
        headers={[
          {
            name: "Address",
            renderKey: "address",
            render: ({ line1, line2, city, state, zipcode }) => (
              <span>
                {interleave(
                  [[line1, line2].filter(Boolean).join(" "), `${city}, ${state} ${zipcode}`],
                  <br key="br" />
                )}
              </span>
            )
          },
          {
            name: "Phone Number(s)",
            renderKey: "phones",
            render: ({ mobilePhone, homePhone, otherPhone }) =>
              [
                { phone: mobilePhone, phoneType: "Mobile" },
                { phone: homePhone, phoneType: "Home" },
                { phone: otherPhone, phoneType: "Other" }
              ]
                .filter(({ phone }) => phone)
                .map(({ phone, phoneType }) => (
                  <span key={phone}>
                    {FormHelpers.formatPhoneNumber(phone)} ({phoneType})
                    <br />
                  </span>
                ))
          },
          {
            name: "Personal Email",
            renderKey: "personalEmail",
            render: ({ personalEmail }) => <a href={`mailto:${personalEmail}`}>{personalEmail}</a>
          },
          {
            name: "Emergency Contact",
            renderKey: "emergencyContact",
            render: ({ emergencyContactName, emergencyContactPhone }) => (
              <span>
                {emergencyContactName}
                <br />
                {FormHelpers.formatPhoneNumber(emergencyContactPhone)}
              </span>
            ),
            tableDataCellProps: { className: "text-right" },
            tableHeaderCellProps: { className: "text-right" }
          }
        ]}
        data={staffMemberQuery.data ? [staffMemberQuery.data] : []}
        onRowClick={onEdit}
      />
    </>
  );
};

const interleave = <TArrayElement, TElement>(arr: TArrayElement[], el: TElement): (TArrayElement | TElement)[] =>
  arr.flatMap((x, i) => (i === arr.length - 1 ? [x] : [x, el]));
