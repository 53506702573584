import { forwardRef, useId } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";

interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  isRequired?: boolean;
  errorMessage?: string;
  validationState?: "success" | "error";
  // TODO: we need to remove col class from all form so we can handle this in a container higher up
  disableColClass?: boolean;
  error?: FieldError;
  isLoading?: boolean;
}

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      label,
      validationState,
      isRequired = false,
      errorMessage,
      disableColClass = false,
      error,
      isLoading = false,
      disabled,
      ...selectProps
    },
    ref
  ) => {
    const id = useId();
    const errorMessageId = useId();

    return (
      <div className={classNames("form-floating", { col: !disableColClass })}>
        <select
          {...selectProps}
          className={classNames("form-select", selectProps.className, {
            "is-invalid": validationState === "error" || error,
            "is-valid": validationState === "success",
            "is-loading": isLoading
          })}
          disabled={disabled || isLoading}
          id={id}
          ref={ref}
          aria-invalid={validationState === "error" ? "true" : "false"}
          aria-errormessage={errorMessageId}
        />
        <label htmlFor={id}>
          {label}
          {isRequired && (
            <>
              {" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </>
          )}
        </label>
        {(!!errorMessage || !!error?.message) && (
          <div id={errorMessageId} className="invalid-feedback">
            {errorMessage || error?.message}
          </div>
        )}
        {isLoading && (
          <div
            className="spinner-border spinner-border-sm"
            style={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              width: "1rem",
              height: "1rem",
              pointerEvents: "none"
            }}
          />
        )}
      </div>
    );
  }
);

SelectField.displayName = "SelectField";
