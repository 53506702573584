import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect } from "react";
import { useForm, type UseFormReturn } from "react-hook-form";
import z from "zod";

import { states } from "../../data/states";
import { useCityAndState } from "../../hooks/use-azure-maps-city-state";
import { DefaultEditableStaffMemberContactDetail, type StaffMemberContactDetails } from "../../hooks/use-user";
import { FormHelpers } from "../forms";
import { FormRow } from "../forms/form-row";
import { SelectField } from "../forms/select-field";
import { InputField } from "../forms/input-field";
import { PhoneNumberField } from "../forms/phone-number-field";
import { zipcodeSchema } from "../forms/form-helpers";

export const EditContactForm: React.FC<{
  editContactForm: UseFormReturn<EditContactFormValues>;

  isCurrentStep: boolean;
}> = ({
  editContactForm: {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors }
  },
  isCurrentStep
}) => {
  const zipCodeFieldValue = watch("zipcode") ?? "";
  const cityAndStateQuery = useCityAndState(zipCodeFieldValue ?? "", {
    enabled: FormHelpers.isValidZipcode(zipCodeFieldValue) && (!watch("city") || !watch("state"))
  });

  useEffect(() => {
    if (cityAndStateQuery?.data?.city && cityAndStateQuery?.data?.state) {
      setValue("city", cityAndStateQuery.data.city);
      setValue("state", cityAndStateQuery.data.state);
    }
  }, [cityAndStateQuery.data]);

  const onChangePhoneNumber = useCallback(() => {
    trigger("phoneNumbers");
  }, [trigger]);

  if (!isCurrentStep) {
    return null;
  }

  return (
    <form className="form step-body">
      <FormRow>
        <InputField label="First Name" isRequired error={errors.firstName} {...register("firstName")} />
        <InputField label="Last Name" isRequired error={errors.lastName} {...register("lastName")} />
      </FormRow>
      <FormRow>
        <InputField label="Personal Email" isRequired error={errors.personalEmail} {...register("personalEmail")} />
      </FormRow>
      <FormRow>
        <InputField label="Address 1" error={errors.line1} {...register("line1")} />
        <InputField label="Address 2" error={errors.line2} {...register("line2")} />
      </FormRow>
      <FormRow>
        <InputField label="ZIP" error={errors.zipcode} {...register("zipcode")} />
        <InputField label="City" error={errors.city} {...register("city")} />
        <SelectField label="State" isRequired error={errors.state} {...register("state")}>
          <option value="">Select a State</option>
          {states.map(({ name, abbreviation }) => (
            <option key={abbreviation} value={abbreviation}>
              {name}
            </option>
          ))}
        </SelectField>
      </FormRow>
      <FormRow className="align-items-start">
        <PhoneNumberField
          label="Mobile Phone"
          {...register("mobilePhone", { onChange: onChangePhoneNumber })}
          error={errors.mobilePhone ?? errors.phoneNumbers}
          validationState={errors.phoneNumbers && "error"}
        />
        <PhoneNumberField
          label="Home Phone"
          {...register("homePhone", { onChange: onChangePhoneNumber })}
          error={errors.homePhone}
          validationState={errors.phoneNumbers && "error"}
        />
        <PhoneNumberField
          label="Other Phone"
          {...register("otherPhone", { onChange: onChangePhoneNumber })}
          error={errors.otherPhone}
          validationState={errors.phoneNumbers && "error"}
        />
      </FormRow>

      <h2 className="card-subheader">Emergency Contact</h2>
      <FormRow>
        <InputField label="Name" error={errors.emergencyContactName} {...register("emergencyContactName")} />
        <PhoneNumberField label="Phone" error={errors.emergencyContactPhone} {...register("emergencyContactPhone")} />
      </FormRow>
    </form>
  );
};

const editContactFormSchema = z
  .object({
    firstName: z.string().trim().min(1, { message: "First name is required" }),
    lastName: z.string().trim().min(1, { message: "Last name is required" }),
    mobilePhone: z.string().trim().refine(FormHelpers.isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    homePhone: z.string().trim().refine(FormHelpers.isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    otherPhone: z.string().trim().refine(FormHelpers.isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    // fake field to hold "must have at least one phone number" error
    phoneNumbers: z.string().optional(),
    personalEmail: z.string().trim().email(),
    line1: z.string().trim(),
    line2: z.string().trim(),
    zipcode: zipcodeSchema.or(z.literal("")),
    city: z.string().trim(),
    state: z.string().min(1, { message: "Please select a state" }),
    emergencyContactName: z.string().trim(),
    emergencyContactPhone: z
      .string()
      .trim()
      .refine(FormHelpers.isValidOptionalPhoneNumber, { message: "Invalid phone number" })
  })
  .refine(({ mobilePhone, homePhone, otherPhone }) => mobilePhone || homePhone || otherPhone, {
    message: "At least one phone number is required",
    path: ["phoneNumbers"]
  });

type EditContactFormValues = z.infer<typeof editContactFormSchema>;

export const useEditContactForm = (staffMemberContactDetails: StaffMemberContactDetails | undefined) =>
  useForm<EditContactFormValues>({
    values: {
      ...DefaultEditableStaffMemberContactDetail,
      ...staffMemberContactDetails
    },
    resolver: zodResolver(editContactFormSchema),
    mode: "onTouched"
  });
