import React from "react";

import { useAccessControl } from "../../app/access-control-context";
import * as Permissions from "../../data/permissions";
import { StaffMember } from "../../hooks/use-user";
import { NavTab, TabContainer, TabContent, TabPanel } from "../nav-tabs/nav-tabs";
import { Access } from "./tab-content/access";
import { CenterAccess } from "./tab-content/center-access";
import { Contact } from "./tab-content/contact";
import { useEditStaffContactFormModal } from "./tab-content/edit-staff-contact-form";
import { Position } from "./tab-content/position";
import { Schedules } from "./tab-content/schedules";
import { StaffNotes } from "./tab-content/staff-notes";
import { Subjects } from "./tab-content/subjects";
import { AppointmentAvailability } from "./tab-content/appointment-availability";

export interface StaffDetailProps {
  staff?: StaffMember;
}

export const StaffDetail: React.FC<StaffDetailProps> = ({ staff }) => {
  const { hasPermission, hasFeatureAccess } = useAccessControl();

  const { renderEditStaffContactModal, showEditStaffContactModal } = useEditStaffContactFormModal({
    staffMemberId: staff?.id
  });

  if (!staff?.id || !hasPermission || !hasFeatureAccess) {
    return null;
  }

  const canAccessStaffSchedules =
    hasFeatureAccess("staff_schedules") && hasPermission([Permissions.SCHEDULE_EDIT, Permissions.SCHEDULE_VIEW]);

  const canAccessStaffApptAvailability =
    hasFeatureAccess("staff_availability") && hasPermission([Permissions.SCHEDULE_EDIT, Permissions.SCHEDULE_VIEW]);

  return (
    <>
      <TabContainer className="scroll-container-fixed">
        <NavTab href="#tab-contact" isActive>
          <span className="fas icon fa-info-circle" aria-hidden="true" />
          Info
        </NavTab>
        <NavTab href="#tab-position">
          <span className="fas icon fa-id-card-clip" aria-hidden="true" />
          Position
        </NavTab>
        {hasFeatureAccess("staff_subjects") && (
          <NavTab href="#tab-subjects">
            <span className="fas icon fa-chalkboard-teacher" aria-hidden="true" />
            Subjects
          </NavTab>
        )}
        {canAccessStaffApptAvailability && (
          <NavTab href="#tab-scheduling">
            <span className="fas icon fa-clock" aria-hidden="true" />
            Availability
          </NavTab>
        )}
        <NavTab href="#tab-access">
          <span className="fas icon fa-user-lock" aria-hidden="true" />
          Access
        </NavTab>
        <NavTab href="#tab-center-access">
          <span className="fas icon fa-school" aria-hidden="true" />
          Centers
        </NavTab>
        {canAccessStaffSchedules && (
          <NavTab href="#tab-schedules">
            <span className="fas icon fa-clock" aria-hidden="true" />
            Schedules
          </NavTab>
        )}
        <NavTab href="#tab-staff-notes">
          <span className="fas icon fa-sticky-note" aria-hidden="true" />
          Notes
        </NavTab>
      </TabContainer>
      <div className="scroll-container-scroll">
        <TabContent>
          <TabPanel id="tab-contact" isCard={true} isActive>
            <Contact
              staffMemberId={staff.id}
              onEdit={hasPermission(Permissions.STAFF_EDIT) ? showEditStaffContactModal : undefined}
            />
            <>{renderEditStaffContactModal()}</>
          </TabPanel>
          {!!hasFeatureAccess?.("staff_subjects") && (
            <TabPanel id="tab-subjects" isCard={true}>
              <Subjects staffMemberId={staff.id} />
            </TabPanel>
          )}
          <TabPanel id="tab-position" isCard={true}>
            <Position staff={staff} />
          </TabPanel>
          <TabPanel id="tab-access" isCard={true}>
            <Access staffMemberId={staff.id} />
          </TabPanel>
          {canAccessStaffSchedules && (
            <TabPanel id="tab-schedules" isCard={true}>
              <Schedules staffMemberId={staff.id} />
            </TabPanel>
          )}
          {canAccessStaffApptAvailability && (
            <TabPanel id="tab-scheduling" isCard={true}>
              <AppointmentAvailability staffMemberId={staff.id} />
            </TabPanel>
          )}
          <TabPanel id="tab-center-access" isCard={true}>
            <CenterAccess staff={staff} />
          </TabPanel>
          <TabPanel id="tab-staff-notes" isCard={true}>
            <StaffNotes staffMemberId={staff.id} />
          </TabPanel>
        </TabContent>
      </div>
    </>
  );
};
