import React, { useEffect, useState } from "react";

import { useAccessControl } from "../../../app/access-control-context";
import { useAvailableCenters, useStaffMember, useUser } from "../../../hooks/use-user";
import * as FormHelpers from "../../forms/form-helpers";
import { LoadingButton } from "../../loading-button/loading-button";
import { LoadingOverlay } from "../../loading-overlay/loading-overlay";
import { useModal } from "../../modal/modal";
import {
  CenterMultiSelector,
  CreateMailbox,
  Field,
  FormDividerRow,
  FormRow,
  PositionSelector,
  RoleSelector
} from "../../forms";

export interface UseAddStaffToCenterModalProps {
  staffMemberId?: string;
}

export const useAddStaffToCenterModal = ({ staffMemberId }: UseAddStaffToCenterModalProps) => {
  const { activeCenterId } = useAccessControl();
  const { show, hide: hideAddStaffToCenterModal, renderModal } = useModal();

  const [isSaving, setIsSaving] = useState(false);
  const { newUserMutator } = useUser(activeCenterId);
  const availableCentersQuery = useAvailableCenters(staffMemberId);
  const staffMemberQuery = useStaffMember({ centerId: activeCenterId, staffMemberId });

  const [createMailbox, setCreateMailbox] = useState<boolean>(staffMemberQuery.data?.hasMailbox ?? false);

  useEffect(() => {
    setCreateMailbox(staffMemberQuery.data?.hasMailbox ?? false);
  }, [staffMemberQuery.data?.hasMailbox]);

  const [role, setRole] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [checkedCenters, setCheckedCenters] = useState<string[]>([]);

  const onSave = () => {
    setIsSaving(true);
    staffMemberQuery.data &&
      newUserMutator &&
      newUserMutator.mutate(
        {
          ...staffMemberQuery.data,

          createMailbox,
          role,
          position,
          startDate,
          jobType: "FT",
          centers: [...checkedCenters]
        },
        {
          onSuccess: () => {
            hideAddStaffToCenterModal();
          },
          onSettled: () => {
            setIsSaving(false);
          }
        }
      );
  };

  const showAddStaffToCenterModal = () => {
    setRole("");
    setPosition("");
    setStartDate("");
    setCheckedCenters([]);
    setCreateMailbox(staffMemberQuery.data?.hasMailbox ?? false);
    show();
  };

  const renderAddStaffToCenterModal = () =>
    renderModal({
      fullscreen: "md-down",
      title: `Add ${staffMemberQuery.data?.displayName} to new Center(s)`,
      body: (
        <LoadingOverlay loading={newUserMutator.isLoading || availableCentersQuery.isLoading}>
          <FormRow>
            <RoleSelector
              mode={"edit"}
              value={role}
              label="System Access Level"
              onChangeValue={setRole}
              onValidate={FormHelpers.isValidRequiredString}
              required
            />
          </FormRow>

          <FormRow>
            <PositionSelector
              mode={"edit"}
              value={position}
              label="Position"
              onChangeValue={setPosition}
              onValidate={FormHelpers.isValidRequiredString}
              required
            />
            <Field
              value={startDate}
              type="date"
              mode={"edit"}
              label="Start Date"
              onValidate={FormHelpers.isValidRequiredString}
              onChangeValue={setStartDate}
              required
            />
          </FormRow>

          <FormRow>
            <CreateMailbox
              value={createMailbox}
              disabled={staffMemberQuery.data?.hasMailbox ?? false}
              onChangeValue={setCreateMailbox}
            />
          </FormRow>

          <FormDividerRow />

          <p>This user will be added to the checked centers</p>

          <CenterMultiSelector
            checkedCenters={checkedCenters}
            availableCenters={availableCentersQuery.data ?? []}
            onChangeValue={setCheckedCenters}
          />
        </LoadingOverlay>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideAddStaffToCenterModal}>
            Cancel
          </button>
          <LoadingButton
            isLoading={isSaving || newUserMutator.isLoading}
            loadingMessage="Saving..."
            className="btn btn-primary"
            onClick={onSave}
            disabled={
              !FormHelpers.isValidAccountInformation({
                createMailbox,
                role,
                position,
                startDate,
                jobType: "FT",
                centers: [...checkedCenters]
              })
            }
          >
            Save
          </LoadingButton>
        </>
      )
    });

  return {
    hideAddStaffToCenterModal,
    showAddStaffToCenterModal,
    renderAddStaffToCenterModal
  };
};
