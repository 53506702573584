import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, type FC } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { z } from "zod";

import { useAccessControl } from "../../../app/access-control-context";
import { states } from "../../../data/states";
import { useCityAndState } from "../../../hooks/use-azure-maps-city-state";
import { useStaffMember, useUpdateStaffMember } from "../../../hooks/use-user";
import { CheckboxFieldLabelFirst } from "../../forms/checkbox-field";
import {
  isValidOptionalEmployeeNumber,
  isValidOptionalPhoneNumber,
  isValidZipcode,
  zipcodeSchema
} from "../../forms/form-helpers";
import { FormRow } from "../../forms/form-row";
import { InputField } from "../../forms/input-field";
import { PhoneNumberField } from "../../forms/phone-number-field";
import { SelectField } from "../../forms/select-field";
import { LoadingOverlay } from "../../loading-overlay/loading-overlay";
import { BasicFormProps } from "../../../forms/form-context-wrapper/form-context-wrapper";
import { LoadingButton } from "../../loading-button/loading-button";
import { useFormModal } from "../../modal/modal";

interface EditContactFormProps extends BasicFormProps<EditContactFormValues> {
  showEmployeeNumber?: boolean;
  businessEmail?: string;
  hasMailbox?: boolean;
  isLoading?: boolean;
}

export const EditStaffContactForm: FC<EditContactFormProps> = ({
  showEmployeeNumber = false,
  methods,
  isLoading,
  businessEmail,
  hasMailbox
}) => {
  const {
    register,
    setValue,
    control,
    formState: { errors }
  } = methods ??
  ({
    formState: {},
    trigger: () => {
      return;
    }
  } as UseFormReturn<EditContactFormValues>);
  const zipCodeFieldValue = useWatch({ control, name: "zipcode", defaultValue: "" });
  const cityFieldValue = useWatch({ control, name: "city", defaultValue: "" });
  const stateFieldValue = useWatch({ control, name: "state", defaultValue: "" });
  const cityAndStateQuery = useCityAndState(zipCodeFieldValue, {
    enabled: isValidZipcode(zipCodeFieldValue) && (!cityFieldValue || !stateFieldValue)
  });

  const onChangePhoneNumber = useCallback(() => {
    methods?.trigger("phoneNumbers");
  }, [methods?.trigger]);

  useEffect(() => {
    if (cityAndStateQuery?.data?.city && cityAndStateQuery?.data?.state && setValue) {
      setValue("city", cityAndStateQuery.data.city);
      setValue("state", cityAndStateQuery.data.state);
    }
  }, [cityAndStateQuery.data]);

  return (
    <LoadingOverlay loading={isLoading}>
      <div className="form" data-testid="staff-details-tab-content-contact">
        <FormRow>
          <InputField label="First Name" isRequired {...register("firstName")} error={errors.firstName} />
          <InputField label="Last Name" isRequired {...register("lastName")} error={errors.lastName} />
        </FormRow>

        <FormRow className="my-1">
          {showEmployeeNumber && (
            <InputField label="HR ID" error={errors.employeeNumber} {...register("employeeNumber")} />
          )}
          <InputField
            label="HLC Account"
            value={businessEmail ?? defaultStaffMemberContactValues.businessEmail}
            disabled
            readOnly
          />
        </FormRow>

        <FormRow className="my-1">
          <CheckboxFieldLabelFirst
            label="Grant HLC Email Account Access"
            labelStyle={{ paddingLeft: "5px" }}
            disabled={hasMailbox}
            style={{ height: "20px", width: "20px", marginTop: "6px" }}
            {...register("createMailbox")}
          />
        </FormRow>

        <FormRow>
          <InputField label="Address 1" {...register("line1")} error={errors.line1} />
          <InputField label="Address 2" {...register("line2")} error={errors.line2} />
        </FormRow>

        <FormRow>
          <InputField label="ZIP" error={errors.zipcode} {...register("zipcode")} />
          <InputField label="City" {...register("city")} error={errors.city} />
          <SelectField label="State" isRequired error={errors.state} {...register("state")}>
            <option value="">Select State</option>
            {states.map(({ abbreviation, name }) => (
              <option key={abbreviation} value={abbreviation}>
                {name}
              </option>
            ))}
          </SelectField>
        </FormRow>

        <FormRow className="align-items-start">
          <PhoneNumberField
            label="Mobile Phone"
            {...register("mobilePhone", { onChange: onChangePhoneNumber })}
            error={errors.mobilePhone ?? errors.phoneNumbers}
            validationState={errors.phoneNumbers && "error"}
          />
          <PhoneNumberField
            label="Home Phone"
            {...register("homePhone", { onChange: onChangePhoneNumber })}
            error={errors.homePhone}
            validationState={errors.phoneNumbers && "error"}
          />
          <PhoneNumberField
            label="Other Phone"
            {...register("otherPhone", { onChange: onChangePhoneNumber })}
            error={errors.otherPhone}
            validationState={errors.phoneNumbers && "error"}
          />
        </FormRow>

        <FormRow>
          <InputField label="Personal Email" isRequired error={errors.personalEmail} {...register("personalEmail")} />
        </FormRow>

        <h3 className="card-subheader">Emergency Contact</h3>
        <FormRow>
          <InputField label="Name" {...register("emergencyContactName")} error={errors.emergencyContactName} />
          <PhoneNumberField label="Phone" error={errors.emergencyContactPhone} {...register("emergencyContactPhone")} />
        </FormRow>

        {showEmployeeNumber && (
          <>
            <h3 className="card-subheader">Employee Number</h3>
            <FormRow>
              <InputField label="HR ID" error={errors.employeeNumber} {...register("employeeNumber")} />
            </FormRow>
          </>
        )}
      </div>
    </LoadingOverlay>
  );
};

const editContactFormSchema = z
  .object({
    firstName: z.string().trim().min(1, { message: "First name is required" }),
    lastName: z.string().trim().min(1, { message: "Last name is required" }),
    mobilePhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    homePhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    otherPhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    // fake field to hold "must have at least one phone number" error
    phoneNumbers: z.string().optional(),
    createMailbox: z.boolean(),
    personalEmail: z.string().trim().email(),
    line1: z.string().trim(),
    line2: z.string().trim(),
    zipcode: zipcodeSchema.or(z.literal("")),
    city: z.string().trim(),
    state: z.string().min(1, { message: "Please select a state" }),
    emergencyContactName: z.string().trim(),
    emergencyContactPhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "Invalid phone number" }),
    employeeNumber: z
      .string()
      .trim()
      .refine(isValidOptionalEmployeeNumber)
      .transform(it => it || null)
  })
  .refine(({ mobilePhone, homePhone, otherPhone }) => mobilePhone || homePhone || otherPhone, {
    message: "At least one phone number is required",
    path: ["phoneNumbers"]
  });

type EditContactFormValues = z.infer<typeof editContactFormSchema>;

interface UseEditStaffContactFormModalProps {
  staffMemberId?: string;
}

export const useEditStaffContactFormModal = ({ staffMemberId }: UseEditStaffContactFormModalProps) => {
  const { hasFeatureAccess, activeCenterId } = useAccessControl();
  const staffMemberQuery = useStaffMember({ centerId: activeCenterId, staffMemberId });
  const updateStaffMemberMutator = useUpdateStaffMember(activeCenterId, staffMemberId);

  const {
    renderModal,
    hide: hideEditStaffContactModal,
    show: showEditStaffContactModal,
    formId
  } = useFormModal({
    name: "edit-staff-contact-form",
    useFormProps: {
      values: {
        ...defaultStaffMemberContactValues,
        ...staffMemberQuery.data,
        employeeNumber: staffMemberQuery.data?.employeeNumber ? `${staffMemberQuery.data?.employeeNumber}` : "",
        createMailbox: staffMemberQuery.data?.hasMailbox ?? defaultStaffMemberContactValues.hasMailbox
      },
      resolver: zodResolver(editContactFormSchema),
      mode: "onTouched"
    },
    onSubmit: formValues => {
      updateStaffMemberMutator.mutate(
        { ...defaultStaffMemberContactValues, ...staffMemberQuery.data, ...formValues },
        { onSuccess: hideEditStaffContactModal }
      );
    }
  });

  const renderEditStaffContactModal = () =>
    renderModal({
      className: "edit-staff-contact-form-modal",
      title: "Edit Staff Contact",
      size: "lg",
      fullscreen: "lg-down",
      body: (
        <EditStaffContactForm
          showEmployeeNumber={hasFeatureAccess && hasFeatureAccess("employee_id")}
          businessEmail={staffMemberQuery.data?.businessEmail}
          hasMailbox={staffMemberQuery.data?.hasMailbox}
          isLoading={updateStaffMemberMutator.isLoading || staffMemberQuery.isLoading}
        />
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideEditStaffContactModal}>
            Cancel
          </button>
          <LoadingButton
            className="btn btn-primary"
            type="submit"
            form={formId}
            isLoading={updateStaffMemberMutator.isLoading}
            loadingMessage="Saving..."
          >
            Save
          </LoadingButton>
        </>
      )
    });

  return {
    hideEditStaffContactModal,
    showEditStaffContactModal,
    renderEditStaffContactModal
  };
};

export const defaultStaffMemberContactValues = {
  id: "",
  firstName: "",
  lastName: "",
  displayName: "",
  businessEmail: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  zipcode: "",
  mobilePhone: "",
  homePhone: "",
  otherPhone: "",
  personalEmail: "",
  emergencyContactName: "",
  emergencyContactPhone: "",
  isActive: false,
  hasMailbox: false,
  createMailbox: false,
  employeeNumber: ""
} as const;
